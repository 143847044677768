import { ReducerStateType } from '@modules/users/user-listing/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  offset: 0,
  limit: 25,
  search: '',
  order: 'desc',
  sortBy: 'profiling_status_last_updated',
  clearPage: true,
  totalCount: 0
} as ReducerStateType;

export const screeningAllListSlice = createSlice({
  name: 'screeningAllListing',
  initialState,
  reducers: {
    resetScreeningAllListingValues: state => ({
      ...state,
      offset: 0,
      limit: 25,
      search: '',
      order: 'desc',
      sortBy: 'profiling_status_last_updated',
      totalCount: 0
    }),
    updateScreeningAllListingValues: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    updateScreeningAllListingClearPage: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      clearPage: payload
    })
  }
});

export const {
  resetScreeningAllListingValues,
  updateScreeningAllListingValues,
  updateScreeningAllListingClearPage
} = screeningAllListSlice.actions;

export default screeningAllListSlice.reducer;
